import { gql } from '@/shared/graphql/generated';

export const getAllSwapsQuery = gql(/* GraphQL */ `
  query GetAllSwaps($first: Int, $offset: Int, $destinationAddress: String) {
    allSwaps(
      orderBy: ID_DESC
      offset: $offset
      first: $first
      filter: {
        destinationAddress: { includesInsensitive: $destinationAddress }
        type: { notEqualTo: GAS }
      }
    ) {
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Swap
        }
      }
      totalCount
    }
  }
`);

export const getSwapByNativeIdQuery = gql(/* GraphQL */ `
  query GetSwapByNativeId($nativeId: BigInt!) {
    swap: swapByNativeId(nativeId: $nativeId) {
      ...Swap
    }
  }
`);

export const getAllSwapsByAssetQuery = gql(/* GraphQL */ `
  query GetAllSwapsByAsset($first: Int, $offset: Int, $baseAsset: ChainflipAsset!) {
    allSwaps(
      filter: {
        or: [
          { sourceAsset: { equalTo: $baseAsset } }
          { destinationAsset: { equalTo: $baseAsset } }
        ]
      }
      orderBy: ID_DESC
      offset: $offset
      first: $first
    ) {
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Swap
        }
      }
      totalCount
    }
  }
`);

export const getSwapAggregates = gql(/* GraphQL */ `
  query GetSwapAggregates(
    $minSwapExecutedTimestamp: Datetime! = "2013-01-01T00:00:00.000Z"
    $maxSwapExecutedTimestamp: Datetime
  ) {
    oneLegSwaps: allSwaps(
      filter: {
        and: [
          { intermediateAmount: { isNull: true } }
          {
            swapExecutedBlockTimestamp: {
              greaterThanOrEqualTo: $minSwapExecutedTimestamp
              lessThanOrEqualTo: $maxSwapExecutedTimestamp
            }
          }
        ]
      }
    ) {
      aggregatesBySourceAsset: groupedAggregates(
        groupBy: [SOURCE_ASSET, SWAP_EXECUTED_BLOCK_TIMESTAMP_TRUNCATED_TO_HOUR]
      ) {
        ...SwapAggregatesFragment
      }
      aggregatesByDestAsset: groupedAggregates(
        groupBy: [DESTINATION_ASSET, SWAP_EXECUTED_BLOCK_TIMESTAMP_TRUNCATED_TO_HOUR]
      ) {
        ...SwapAggregatesFragment
      }
    }
    twoLegSwaps: allSwaps(
      filter: {
        and: [
          { intermediateAmount: { isNull: false } }
          {
            swapExecutedBlockTimestamp: {
              greaterThanOrEqualTo: $minSwapExecutedTimestamp
              lessThanOrEqualTo: $maxSwapExecutedTimestamp
            }
          }
        ]
      }
    ) {
      aggregatesBySourceAsset: groupedAggregates(
        groupBy: [SOURCE_ASSET, SWAP_EXECUTED_BLOCK_TIMESTAMP_TRUNCATED_TO_HOUR]
      ) {
        ...SwapAggregatesFragment
      }
      aggregatesByDestAsset: groupedAggregates(
        groupBy: [DESTINATION_ASSET, SWAP_EXECUTED_BLOCK_TIMESTAMP_TRUNCATED_TO_HOUR]
      ) {
        ...SwapAggregatesFragment
      }
    }
  }

  fragment SwapAggregatesFragment on SwapAggregates {
    assetAndHour: keys
    distinctCount {
      id
    }
    sum {
      swapOutputValueUsd
      intermediateValueUsd
    }
  }
`);

gql(/* GraphQL */ `
  fragment ChannelInfo on SwapChannel {
    id
    channelId
    depositAddress
    destinationAddress
    destinationChain
    issuedEventId
    sourceChain
    sourceAsset
    destinationChain
    destinationAsset
    brokerCommissionRate
    maxBoostFeeBps
    isExpired
    sourceChainExpiryBlock
    openingFeePaid
    fokRefundAddress
    fokMinPriceX128
    issuedBlock: blockByIssuedBlockId {
      id
      timestamp
    }
    issuedEvent: eventByIssuedEventId {
      indexInBlock
    }
  }
`);

export const getSwapChannelData = gql(/* GraphQL */ `
  query GetSwapChannelData(
    $channelId: BigInt!
    $sourceChain: ChainflipChain!
    $issuedBlockId: Int!
  ) {
    channel: swapChannelByIssuedBlockIdAndSourceChainAndChannelId(
      channelId: $channelId
      issuedBlockId: $issuedBlockId
      sourceChain: $sourceChain
    ) {
      ...ChannelInfo
      ignoredDeposits: ignoredDepositsBySwapDepositChannelId(orderBy: EVENT_ID_DESC) {
        nodes {
          id
          amount
          reason
          event: eventByEventId {
            id
            indexInBlock
            block: blockByBlockId {
              id
              timestamp
            }
          }
        }
      }
      beneficiaries: swapChannelBeneficiariesByDepositChannelId {
        nodes {
          brokerCommissionRateBps
          type
          commissions: swapCommissionsBySwapChannelBeneficiaryId {
            aggregates {
              sum {
                valueUsd
                amount
              }
            }
          }
          broker: brokerByBrokerId {
            account: accountByAccountId {
              alias
              idSs58
            }
          }
        }
      }
      swaps: swapsByDepositChannelId(orderBy: NATIVE_ID_DESC) {
        nodes {
          destinationAsset
          nativeId
          sourceAsset
          depositAmount
          depositValueUsd
          egressAmount
          egressValueUsd
          swapScheduledEvent: eventBySwapScheduledEventId {
            block: blockByBlockId {
              timestamp
            }
          }
          intermediateAmount
          intermediateValueUsd
          effectiveBoostFeeBps
          executedEvent: eventBySwapExecutedEventId {
            block: blockByBlockId {
              timestamp
            }
          }
        }
      }
    }
    lastBlock: allBlocks(last: 1) {
      nodes {
        id
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment OpenChannel on SwapChannel {
    id
    brokerCommissionRate
    channelId
    destinationAddress
    destinationAsset
    destinationChain
    sourceAsset
    sourceChain
    maxBoostFeeBps
    issuedEvent: eventByIssuedEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    broker: brokerByBrokerId {
      account: accountByAccountId {
        idSs58
        alias
      }
    }
    swaps: swapsByDepositChannelId {
      aggregates {
        sum {
          egressValueUsd
          intermediateValueUsd
        }
      }
    }
  }
`);

export const getOpenSwapChannels = gql(/* GraphQL */ `
  query GetOpenSwapChannels($first: Int!, $offset: Int) {
    channels: allSwapChannels(
      condition: { isExpired: false }
      orderBy: ISSUED_EVENT_ID_DESC
      offset: $offset
      first: $first
    ) {
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...OpenChannel
        }
      }
      totalCount
    }
  }
`);

gql(/* GraphQL */ `
  fragment BrokerSwapChannel on SwapChannel {
    ...OpenChannel
    isExpired
    openingFeePaid
  }
`);

export const getSwapChannelsByBrokerIdQuery = gql(/* GraphQL */ `
  query GetSwapChannelsByBrokerId($idSs58: String!, $first: Int, $offset: Int) {
    account: accountByIdSs58(idSs58: $idSs58) {
      broker: brokerByAccountId {
        channels: swapChannelsByBrokerId(
          orderBy: ISSUED_EVENT_ID_DESC
          offset: $offset
          first: $first
        ) {
          totalCount
          edges {
            node {
              ...BrokerSwapChannel
            }
          }
        }
      }
    }
  }
`);

export const getSwapChannelsByAffiliateBrokerIdQuery = gql(/* GraphQL */ `
  query GetSwapChannelsByAffiliateBrokerId($idSs58: String!, $first: Int, $offset: Int) {
    account: accountByIdSs58(idSs58: $idSs58) {
      broker: brokerByAccountId {
        beneficiaryChannels: swapChannelBeneficiariesByBrokerId(
          orderBy: DEPOSIT_CHANNEL_ID_DESC
          offset: $offset
          first: $first
          condition: { type: AFFILIATE }
        ) {
          totalCount
          edges {
            node {
              swapChannel: swapChannelByDepositChannelId {
                ...BrokerSwapChannel
              }
            }
          }
        }
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment SwapInfo on Swap {
    nativeId
    sourceAsset
    sourceChain
    destinationAsset
    destinationChain
    destinationAddress
    depositAmount
    depositValueUsd
    swapInputAmount
    swapInputValueUsd
    swapOutputAmount
    swapOutputValueUsd
    egressAmount
    egressValueUsd
    refundEgressAmount
    refundEgressValueUsd
    intermediateAmount
    intermediateValueUsd
    effectiveBoostFeeBps
    type
    ccmMessage
    egressIgnoredEventId
    transactionRef: transactionRefsBySwapId {
      nodes {
        ref
      }
    }
    ccmDepositReceivedEvent: eventByCcmDepositReceivedEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    scheduledEvent: eventBySwapScheduledEventId {
      indexInBlock
      args
      block: blockByBlockId {
        id
        timestamp
      }
    }
    executedEvent: eventBySwapExecutedEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    egressScheduledEvent: eventByEgressScheduledEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    egressIgnoredEvent: eventByEgressIgnoredEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    broadcast: broadcastByBroadcastId {
      ...BroadcastInfo
    }
    refundEgressScheduledEvent: eventByRefundEgressScheduledEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    refundEgressIgnoredEvent: eventByRefundEgressIgnoredEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    refundBroadcast: broadcastByRefundBroadcastId {
      ...BroadcastInfo
    }
    channel: swapChannelByDepositChannelId {
      ...ChannelInfo
    }
    swapFeesBySwapId {
      edges {
        node {
          ...SwapFee
        }
      }
    }
    error: stateChainErrorByStateChainErrorId {
      name
      docs
    }
    depositBlock: foreignChainTrackingByForeignChainDepositBlockId {
      stateChainTimestamp
    }
  }
`);

gql(/* GraphQL */ `
  fragment BroadcastInfo on Broadcast {
    broadcastRequestedEventId
    broadcastAbortedEventId
    broadcastSuccessEventId
    transactionRef: transactionRefsByBroadcastId {
      nodes {
        ref
      }
    }
    successEvent: eventByBroadcastSuccessEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    requestedEvent: eventByBroadcastRequestedEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    abortedEvent: eventByBroadcastAbortedEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    transactionPayload
  }
`);

gql(/* GraphQL */ `
  fragment SwapFee on SwapFee {
    valueUsd
    amount
    asset
    type
  }
`);

export const getSwapData = gql(/* GraphQL */ `
  query GetSwapDataByNativeId($nativeId: BigInt!) {
    swap: swapByNativeId(nativeId: $nativeId) {
      ...SwapInfo
    }
  }
`);

export const getSwapPoolsAggregatesQuery = gql(/* GraphQL */ `
  query GetSwapPoolsAggregates {
    poolSwaps: allSwaps {
      groupedAggregates(groupBy: [SOURCE_ASSET, DESTINATION_ASSET]) {
        fromAssetToAsset: keys
        sum {
          swapOutputValueUsd
          intermediateValueUsd
        }
      }
    }
  }
`);
