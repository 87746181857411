import {
  type SwapInfoFragment,
  type SwapFragment,
  type SwapFeeType,
} from '@/shared/graphql/generated/graphql';
import { TokenAmount, chainflipAssetMap } from '@/shared/utils';

export const getSwapStatus = (
  swap: Pick<
    SwapFragment,
    | 'broadcast'
    | 'egressIgnoredEventId'
    | 'egressScheduledEventId'
    | 'refundBroadcast'
    | 'refundEgressIgnoredEventId'
    | 'refundEgressScheduledEventId'
  >,
) => {
  if (swap.broadcast?.broadcastSuccessEventId) return 'BROADCAST_SUCCEEDED';
  if (swap.broadcast?.broadcastAbortedEventId) return 'BROADCAST_ABORTED';
  if (swap.broadcast?.broadcastRequestedEventId) return 'BROADCAST_REQUESTED';
  if (swap.egressScheduledEventId) return 'EGRESS_SCHEDULED';
  if (swap.egressIgnoredEventId) return 'EGRESS_IGNORED';

  if (swap.refundBroadcast?.broadcastSuccessEventId) return 'REFUND_BROADCAST_SUCCEEDED';
  if (swap.refundBroadcast?.broadcastAbortedEventId) return 'REFUND_BROADCAST_ABORTED';
  if (swap.refundBroadcast?.broadcastRequestedEventId) return 'REFUND_BROADCAST_REQUESTED';
  if (swap.refundEgressScheduledEventId) return 'REFUND_EGRESS_SCHEDULED';
  if (swap.refundEgressIgnoredEventId) return 'REFUND_EGRESS_IGNORED';

  return 'SWAP_SCHEDULED';
};

export const isSuccessStatus = (status: SwapStatus): status is 'BROADCAST_SUCCEEDED' =>
  status === 'BROADCAST_SUCCEEDED';

export const isRefundedStatus = (status: SwapStatus): status is 'REFUND_BROADCAST_SUCCEEDED' =>
  status === 'REFUND_BROADCAST_SUCCEEDED';

export const isFailedStatus = (
  status: SwapStatus,
): status is
  | 'BROADCAST_ABORTED'
  | 'EGRESS_IGNORED'
  | 'REFUND_BROADCAST_ABORTED'
  | 'REFUND_EGRESS_IGNORED' =>
  status === 'BROADCAST_ABORTED' ||
  status === 'EGRESS_IGNORED' ||
  status === 'REFUND_BROADCAST_ABORTED' ||
  status === 'REFUND_EGRESS_IGNORED';

export type SwapStatus = ReturnType<typeof getSwapStatus>;

export const getFee = (swap: SwapInfoFragment, feeType: SwapFeeType) => {
  const fees = swap.swapFeesBySwapId.edges;
  const fee = fees.find(({ node }) => node.type === feeType);

  return (
    fee && {
      asset: fee.node.asset,
      amount: new TokenAmount(fee.node.amount, chainflipAssetMap[fee.node.asset].decimals),
      valueUsd: Number(fee.node.valueUsd ?? 0),
    }
  );
};
